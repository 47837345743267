import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import MaterialTextArea from "../layout/MaterialComponents/MaterialTextArea";
import { alumniStoryValidate } from "./EmailusValidation";
import getIPAddress, { unescapeHTML, roleChange, pageNameByPath, fireAnalyticsEvent, fireNonRFISubmitEvent} from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocus'
import axios from 'axios';
import checkmark from '../layout/images/checkmark.svg';
import Button from '../layout/MaterialComponents/button/Button';
import { initValues } from '../common/configTools';

const initialAlumniStoryValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber:'',
    graduationYear:'',
    flex_more_info__c: '',
    degreeLevel:'',
    flex_Employer_Disclosed_on_RFI__c:'',
    requestingUrl: '',
    disclaimer:'By submitting, you consent to University of Phoenix contacting you regarding our educational programs and services using email, telephone or text — including our use of automated technology for calls, pre-recorded messages and periodic texts to any wireless number you provide. Message and data rates may apply. This consent is not required to purchase goods or services and you may always call us directly at <a target="_blank" href="tel:8007952586">1-800-795-2586</a>.',
    formName: 'AlumniStory',
};

const formRef = {};
let prevDegreeLevel;
//let defaultTextHTML = "<h1>Email us</h1>";
const disclaimerText = initialAlumniStoryValues.disclaimer;

function generateYearsBetween(startYear = 1976, endYear) {
    let endDate = endYear || new Date().getFullYear();
    let years = [];
    for (var i = endDate; i >= startYear; i--) {
      years.push(endDate);
      endDate--;
    }
    return years;
}

const yearsArray = generateYearsBetween();
const yearOpts = yearsArray.map((year) => {
    return ( { label: year, value: year} );
});

const onTextAreaChange= (e) => {
    formRef.current.values.flex_more_info__c = e.target.value;
  }

const degreeLevel = [
    { label: "Associate", value: "Associate" },
    { label: "Bachelors", value: "Bachelors" },
    { label: "Masters", value: "Masters" },
    { label: "Doctorate", value: "Doctorate" },
];  

class AlumniStory extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialAlumniStoryValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
    }

    componentDidMount() {
    //    document.title = `Re-entry contact-us`;
        const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href;
        prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : 'Associate';
        if (prevDegreeLevel === 'Associate') {
            formRef.current.values.degreeLevel = prevDegreeLevel;
        }
    //    initValues(initialValues, this.props);
        this.setState({ initialValues });
    //    getIPAddress(this.updateIPAddress);
        const { currentStep } = this.state;
        formStep = currentStep;  
	    formType = 'Non-RFI';
    	formName = 'AlumniStory';
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
    }

    dropdownChange = (name, value) => {
        if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value) {
                prevDegreeLevel = value;
            }
        }
    }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
	        fireAnalyticsEvent('NonRFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }
      

    handleSubmit = (values) => {
    //    hashValues(values);   
        values.firstName = (values.firstName).trim();
        values.lastName = (values.lastName).trim();
        this.setState({ initialValues: values });
        const headers = {'Content-Type': 'application/json'};
        let URL = 'https://www.phoenix.edu/request/sendmail';
        const data = JSON.stringify(values);
        axios.post(URL, data, {headers})
        .then((response) => {
            if (response.data.msg === 'success'){
                console.log("Email sent, awesome!");
                this.setState({
                    initialValues: values,
                    isSubmit: true,
                  });
            }else if(response.data.msg === 'fail'){
                console.log("Oops, something went wrong. Try again")
            }
        })
        .catch(error => {
            console.log (error.message);   
        });
	    fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
    }
    
    render() {
    //    const { classes } = this.props;
        const { isSubmit, initialValues } = this.state;

    //    let titleText = this.props.title || defaultTextHTML;

        return (
            
            <div>
            {/*   <Header />   */ }
                <div className="AlumniStory-main">
                    <Grid container justify='center' className="mobileFormContainer">
                    <Grid container md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">

                     {!isSubmit
                    ? (
                        <>
                        <Grid item md={12} sm={12} lg={12} xs={12} >
                            <div className="reqinfo" > * Required information </div>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => alumniStoryValidate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Alumni contact us"
                                                  style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                            <Grid container >
                                                <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm">                                               
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="firstName" id="firstName"
                                                            label="First name *" ariaRequired="true" ariaLabel="Enter First name" onClick={this.handleAnalyticsRFIFormStart} />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="lastName" id="lastName"
                                                            label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <div className="employer">
                                                            <MaterialPhoneNumber name="phoneNumber" id="phoneNumber" label="Phone number" ariaLabel="Enter phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <SelectField
                                                            id="graduationYear"
                                                            name="graduationYear"
                                                            label="Graduation year *"
                                                            data={yearOpts}
                                                            className="MuiFormControl-fullWidth"
                                                            ariaRequired="true"
                                                            formRef={formRef}
                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                            onChange={this.dropdownChange("graduationYear", props.values.graduationYear)}
                                                        ></SelectField>
                                                        {roleChange()}
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ={["margin-OneStepForm alumni-story"]} >
                                                        <MaterialTextArea
                                                            id="flex_more_info__c"
                                                            name="flex_more_info__c"
                                                            onChange={onTextAreaChange}
                                                            label="Tell us about becoming a Phoenix *"
                                                            classNames="uopx-input more_info_textarea"
                                                            ariaLabel="Tell us about becoming a Phoenix"
                                                            ariaRequired="true"
                                                            placeholder="Suggestions: What inspired you to go to college? What motivated you to keep going? What did you learn about yourself? Any advice for someone considering enrolling?"
                                                        />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <SelectField
                                                            id="degreeLevel"
                                                            name="degreeLevel"
                                                            label="Degree *"
                                                            data={degreeLevel}
                                                            className="MuiFormControl-fullWidth"
                                                            ariaRequired="true"
                                                            formRef={formRef}
                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                            onChange={this.dropdownChange("degreeLevel", props.values.degreeLevel)}
                                                        ></SelectField>
                                                        {roleChange()}
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <div className="employer">
                                                            <MaterialInput name="flex_Employer_Disclosed_on_RFI__c" id="flex_Employer_Disclosed_on_RFI__c"
                                                                label="Employer name" ariaLabel="Employer name"  />
                                                        </div>
                                                    </Grid> 
                                                </Grid>    

                                                    <Grid item md={9} sm={12} lg={12} xs={12} className ={["margin-OneStepForm"]} style={{ alignItems: 'center', display: 'flex' }}>
                                                        <div className="disclaimer">
                                                            { unescapeHTML(disclaimerText) }
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3} sm={12} lg={12} xs={12} className ={["margin-OneStepForm", "submit-btn" ]}>
                                                        <Button size='large' type='submit' className="btn-submit"> Submit </Button>
                                                    </Grid>

                                            </Grid>
							                <ErrorFocus />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        </>
                    )
                    : (
                          <>
                            <Grid item md={12} sm={12} lg={12} xs={12} className='thanksContainer'>
                              <div className='thanksHeader' style={{ fontWeight: '700' }}>
                                <img src={checkmark} alt="Checked Circle" />
                                Thanks for sharing your story
                              </div>
                            </Grid>
                          </>
                        )}
                          </Grid>
                    </Grid>
                </div>
            { /*   <Footer /> */ } 
            </div>
        );
    }
}

AlumniStory.defaultProps = {
    classes: {},
};

AlumniStory.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(AlumniStory);