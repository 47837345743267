/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createGenerateClassName, StylesProvider, withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import Box from '@mui/material/Box';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues';
import MaterialRadio from '../layout/MaterialComponents/MaterialRadio';
//import styles from '../layout/styles/InlineFormStyles';
//import styles from '../layout/styles/Employer-Inline-Styles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import { unescapeHTML, addDropDownOption, processJSONParameter, initValues } from '../common/configTools';
import getIPAddress, {
  getDegreeLevel,
  conditionalCBE,
  loadCookiesData,
  profanityCheck,  
  submitForm,
  updatePrePopCookieValue,
  fireAnalyticsEvent, fireFOS_DlEvent,
} from '../CommonComponents';
import { degreeLevel as degreeLevelOptions,semesterDropdown, dynamicFieldOfStudyDropdown as fieldOfStudyOptions, radioOption } from '../common/constants';
import Button from '../layout/MaterialComponents/button/Button';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
import {communityCollegeList,collegeDropdownDataList} from '../common/communityCollegeList'
import { Cookies } from "react-cookie";


let prevFOS;
let prevCommunityCollegeValue;
let prevDegreeLevel;
let prevState;
const disclaimerText = rfiFormInitialValues.disclaimer;

// Allow unescaped HTML
class CommunityCollegeTransferForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      isAssociateSelected: true,
      communityCollegeDataList:'',
      initialValues: rfiFormInitialValues,
      thankyouPageUrl: '/request-info/thank-you.html',
      showIntlText: false,
      currentStep: 1,
      fieldOfStudyOptions: processJSONParameter(props.fieldOfStudyOptions || [...fieldOfStudyOptions]),
      //degreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelOptions]),
      //initialDegreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelOptions]),
      flex_CommunityDropdown: processJSONParameter(props.communityCollegeList || [...communityCollegeList]),
      flex_CommunityCollegeDropdown: processJSONParameter(props.collegeDropdownDataList || {...collegeDropdownDataList}),
    };
    this.ref = {
      container: React.createRef(),
      form: React.createRef(),
    };
  }

  
  componentDidMount() {
    let { initialValues } = this.state;
    //initialValues = loadCookiesData(initialValues);
    initialValues.isAssociate = '';
    initialValues.flex_CommunityDropdown = '';
    initialValues.flex_CommunityCollegeDropdown = '';
    this.handleZipCodeChange(initialValues.postalCode, initialValues.state);
    initValues(initialValues, this.props);
    addDropDownOption(initialValues.fieldOfStudy, this.state.fieldOfStudyOptions);
    //addDropDownOption(initialValues.degreeLevel, this.state.degreeLevel);
    this.setState({ initialValues });
    prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
    //prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
    if (prevFOS && this.props.theme === 'dynamic') {
      this.resetDegreeLevel(prevFOS)
    };
    if (prevFOS && this.props.theme === 'twostep') {
      this.resetDegreeLevel(prevFOS)
    };
    getIPAddress(this.updateIPAddress).then();
    const { currentStep } = this.state;
    formStep = currentStep;
    if (window.uopxDataLayer) {
      formType = "RFI";
      switch (this.props.theme) {
        case 'twostep':
          formName = 'RFI inline - 2 step';
          break;
        default:
          formName = 'RFI inline - 1 step';
          break;
      }
    }

    if (window.pageData && window.pagePath) {
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
    }

    const onScroll = () => {
      const footerContainerElm = document.querySelector('.sticky-footer-container');
      const formContainerElm = this.ref.container.current;
      if (!footerContainerElm) {
        return;
      }

      const guideContainerTop = formContainerElm.offsetTop;
      const guideContainerBottom = formContainerElm.offsetTop
                + formContainerElm.offsetHeight;
      const screenTop = window.scrollY;
      const screenBottom = window.scrollY + window.innerHeight;
      const isMobileView = window.innerWidth <= 640;

      const isFormNotInTheView = guideContainerTop > screenBottom || guideContainerBottom < screenTop;

      if (isMobileView && isFormNotInTheView) {
        footerContainerElm.setAttribute('style', 'display: block !important');
      } else {
        footerContainerElm.setAttribute('style', 'display: none !important');
      }
    };
    document.addEventListener('scroll', onScroll);
    setTimeout(onScroll, 100);

    if (this.state.RFIStep1 !== true) {
      setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
      this.setState({ RFIStep1: true });
    } 
  }
  isFieldEnabled(fieldName) {
    if (this.props[`${fieldName}Enabled`]) return true;
    let { fieldsEnabled } = this.props;
    switch (this.props.theme) {
      default:
        break;
      case 'twostep':
        // Default two-step form with scholarship question
        if (!fieldsEnabled) fieldsEnabled = 'isScholarship';
        break;     
      }
    return fieldsEnabled && (fieldsEnabled.split(',').map(f => f.trim()).indexOf(fieldName) !== -1);
  }

  isFieldHidden(fieldName) {
    const { fieldsHidden } = this.props;
    return fieldsHidden && (fieldsHidden.split(',').map(f => f.trim()).indexOf(fieldName) !== -1);
  }

  getTitleText() {
    let defaultTextHTML;
    const defaultIntlTextTitle = "<a class='btn-right-arrow' href='https://www.phoenix.edu/request/international-student'>International students start here <span class='right-arrow'></span></a>";
    switch (this.props.theme) {
      default:
        defaultTextHTML = "<h2 class='newOneForm-title' style='font-size: 30px'>Explore all the ways to save when you transfer from community college</h2><p>Share your contact info and an enrollment representative will contact you.</p><p>*Required information</p>";
        break;
      case 'twostep':
        defaultTextHTML = '<h2 class="title-large">Request Information</h2>';
        break;
      case 'dynamic':
        defaultTextHTML = '<h2 class="title-large">Request Information</h2>';
        break;           
    }
    let titleText = this.props.title || defaultTextHTML;
    if (this.state.showIntlText) {
      // International Zip Code:
      titleText += this.props.intlTitle || defaultIntlTextTitle;
    }
    return titleText;
  }

  dropdownChange = (name, value) => {
    // Update the component state
    const { initialValues } = this.state;
    //initialValues[name] = value;
    //this.setState({ initialValues });    
    if (name === 'fieldOfStudy') {
      if (value && prevFOS !== value) {
        updatePrePopCookieValue('fieldOfStudy', value);
	      fireFOS_DlEvent(name, value, formName, formType);
        prevFOS = value;
        initialValues.fieldOfStudy = value;
        if(initialValues.fieldOfStudy === "nondegree") {
            courseCategoryFlag = true;
        }else {
            courseCategoryFlag = false;
            this.resetDegreeLevel(value);
        }
        /*if (this.props.theme === 'twostep' && value !== 'nondegree') {
            this.resetDegreeLevel(value);                                    
        }*/          
      }
    } 
    if (name === 'flex_CommunityDropdown') {
      if (value && prevCommunityCollegeValue !== value) {
        //updatePrePopCookieValue('fieldOfStudy', value);
	      fireFOS_DlEvent(name, value, formName, formType);
        prevCommunityCollegeValue = value;                 
      }
    }    
    if (name === 'degreeLevel') {
      //this.ref.form.current.touched.degreeLevel = true;
      if ((value && prevDegreeLevel !== value)) {
        updatePrePopCookieValue('degreeLevel', value);
        fireFOS_DlEvent(name, value, formName, formType);
        prevDegreeLevel = value;
        initialValues.degreeLevel = value;
        this.validate(initialValues);
      }
    }
  }

  updateIPAddress = (ipAddress) => {
    const { initialValues } = this.state;
    initialValues.ipAddress = ipAddress;
    this.setState({ initialValues });
  }

    changeStep = step => {
      if (step === 2) {
        this.handleNextButton();
        return;
      }
      this.setState({ currentStep: step });
      fireAnalyticsEvent('RFIStep'+ step, formName, formType);
      formStep = this.state.currentStep;
    }

    handleChange = e => {
      const { name, value, checked } = e.target;
      switch (name) {
        case 'isMilitary':
           this.ref.form.current.values.isMilitary = value;
           break;
        case 'isAssociate':
          this.ref.form.current.values.isAssociate = value;
          this.setState({ isAssociateSelected: (value == 'No' ? false : true) });
          break;          
         case 'isRegisteredNurse':
            this.ref.form.current.values.isRegisteredNurse = value;
            this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});          
            break;
          case 'isCBE':
            this.ref.form.current.values.isCBE = checked;
            break;
        default:
          break;
      }
    };

    handleAnalyticsRFIFormStart = () => {
      if (this.state.rfi_begin !== true) {
        fireAnalyticsEvent('RFIStart', formName, formType);
        this.setState({ rfi_begin: true });
      }
    }
    resetDegreeLevel(fosValue) {
      const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel, fosValue, this.ref.form.current.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
      //check if degreeLevel was touched
      //if ((this.ref.form.current.touched['degreeLevel'] === true)) {
      if ((this.ref.form.current.values.degreeLevel !== '' && courseCategoryFlag === false)) {
          var optionExists = newDegreeLevelOptions.some((e) => {this.state.initialValues.degreeLevel === e.value;});
          //if prev degree level doesnt exist, reset
          if (!optionExists) this.ref.form.current.setFieldValue('degreeLevel', '')
      }
      this.setState({ degreeLevel: newDegreeLevelOptions }); // EX: Bachelors, Individual Courses
  }
    
    handleZipCodeChange = (zipcode, state) => {
      this.ref.form.current.values.postalCode = zipcode;
      this.ref.form.current.values.stateProvince = state;
      updatePrePopCookieValue('zipcode', zipcode);
      updatePrePopCookieValue('state', state);

      if (state && prevState !== state) {
        this.updateAndNotify(state);
        prevState = state;
      }

      const showIntlText = zipcode && zipcode.toString().length > 4 && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode);
      if (this.state.showIntlText !== showIntlText) {
        this.setState({ showIntlText });
      }
    }

    updateAndNotify = state => {
      this.setState({ stateProvince: state });
    }
    
    handlePhoneNumberBlur = () => {
      // this.ref.form.current.values.phoneNumber = value;
    }
    getCBEText() {
      let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
      return cbeText;
    }
    isCBEConsent = (event, checked) => {
      //const { checked } = event.target;
      //this.ref.form.current.values.isCBE = checked;
      this.ref.form.current.values.isCBE = checked;
    }

    handleNextButton = () => {
      const values = { ...this.state.initialValues, ...this.ref.form?.current?.values };
      const errors = this.validate(values);
      this.ref.form.current.setStatus(errors);
      if (Object.values(errors).length === 0
            && (this.props.theme === 'twostep' || this.props.theme === 'dynamic')
            && this.state.currentStep === 1) {
        this.setState({ currentStep: 2, initialValues: values });
        fireAnalyticsEvent('RFIStep2', formName, formType);
        formStep = this.state.currentStep;
      } else {
        this.ref.form.current.submitForm();
      }
    }

    

    handleSubmit = formValues => {
      const values = { ...this.state.initialValues, ...formValues };
      const errors = this.validate(values);
      this.ref.form.current.setStatus(errors);
      if ( (this.props.theme === 'twostep' || this.props.theme === 'dynamic') 
            && this.state.currentStep === 1) {
        fireAnalyticsEvent('RFIStep2', formName, formType);
        this.setState({ currentStep: 2, initialValues: values });
        formStep = this.state.currentStep;
      } else {
        if (((values.fullName).trim().indexOf(' ')) > 0) {
          const i = (values.fullName.trim()).indexOf(' ');
          values.firstName = (values.fullName.trim()).substring(0, i); 
          values.lastName = (values.fullName.trim()).substring(i + 1);
        }

        if (values.flex_CommunityCollegeDropdown !== '' && values.flex_CommunityCollegeDropdown !== 'undefined' && values.flex_CommunityCollegeDropdown !== undefined){
          values.flex_Community_College_University__c = values.flex_CommunityDropdown + "-" + values.flex_CommunityCollegeDropdown;
        } else if (values.flex_CommunityDropdown === 'other') {
          values.flex_Community_College_University__c = values.flex_CommunityDropdown + "-" + values.flex_otherCollege;
        } else {
          values.flex_Community_College_University__c = values.flex_CommunityDropdown;
        }
        
        // get cookies details
        const cookies = new Cookies();
        let cookiesValue = cookies.get("ac_prepop");

        if (cookiesValue) {
          let ac_prepopValues = decodeURIComponent(cookiesValue).split("&");
          for (let i = 0; i < ac_prepopValues.length; i++) {
            let key = '';
            let value = '';
            if (ac_prepopValues[i].split("=")[0] !== undefined && ac_prepopValues[i].split("=")[0] !== null){
              key = ac_prepopValues[i].split("=")[0].trim();   
            }
            if (ac_prepopValues[i].split("=")[1] !== undefined && ac_prepopValues[i].split("=")[1] !== null){
              value = ac_prepopValues[i].split("=")[1].trim();   
            }
            if (value !== null && value !== undefined && value !== "undefined" && value !== '' && key !== null && key !== undefined && key !== "undefined" && key !== '' && values.appContext !== 'EMAILCAPTURE') {
              if (key.toLowerCase() === "detail".toLowerCase()) {
                values.campaign = value;
              } else if (key.toLowerCase() === "channel".toLowerCase()) {
                values.source = value;
              }
            }
          }
        }  
        this.setState({ initialValues: values });
        //    values = mapAdditionalFields(values);
        //submitForm(values);
        submitForm(values, this.props);
      }
    }

    isRegisteredNurseOptionVisible() {
      const { initialValues } = this.state;
      return [
        'healthcare',
        'nursing',
      ].indexOf(initialValues.fieldOfStudy) !== -1;
    }

    isMaricopaCommunityListOptionVisible() {
      const { initialValues } = this.state;
      return [
        'Maricopa Community Colleges',   
      ].indexOf(initialValues.flex_CommunityDropdown) !== -1;
    }
    isOtherCollegeVisible(){
      const { initialValues } = this.state;
      return [
        'other',
      ].indexOf(initialValues.flex_CommunityDropdown) !== -1;
    }

    isMilitaryOptionVisible() {
      const { initialValues } = this.state;
      return !!initialValues.fieldOfStudy;
      // return [
      //     'technology'
      // ].indexOf(this.state.fieldOfStudy) !== -1
    }

    validateOneStep(values) {
      const errors = {};
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = 'Field of Study requires a value';
      }
      if (!values.flex_CommunityDropdown) {
        errors.flex_CommunityDropdown = 'Community college requires a value';
      }
      if (this.state.communityCollegeDataList != undefined && this.state.flex_CommunityCollegeDropdown[this.state.communityCollegeDataList] != undefined) {
        if (!values.flex_CommunityCollegeDropdown) {
          errors.flex_CommunityCollegeDropdown = 'Community college requires a value';
        }
      }
      if (this.isRegisteredNurseOptionVisible() && !values.isRegisteredNurse) {
        errors.isRegisteredNurse = 'Registered Nurse requires a value';
      }
      if (!values.postalCode) {
        errors.zipcode = 'Enter a ZIPCode';
      }
      if (values.postalCode && values.postalCode.length !== 5) {
        errors.zipcode = 'Zip codes must include 5 numeric characters';
      }
      if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
        errors.zipcode = 'Zip code must be numeric';
      } else if (values.postalCode && values.stateProvince === 'N/A') {
        errors.zipcode = 'Zip Code appears invalid';
      }
      if (values.flex_Community_College_University__c) {
        const check = profanityCheck(values.flex_Community_College_University__c);
        if (check === false) {
          errors.flex_Community_College_University__c = 'Education appears invalid';
        }
      }
      console.log ("step1 errors", errors);
      return errors;
    }

    validateTwoStep(values) {
      const errors = {};
      if (!values.fullName) {
        errors.fullName = 'First and Last name requires a value';
      } else if (values.fullName.trim().indexOf(' ') === -1) {
        errors.fullName = 'Please enter a First and Last name';
      }
      if (values.fullName) {
        const check = profanityCheck(values.fullName);
        if (check === false) {
          errors.fullName = 'Name appears invalid';
        }
      }

      if (!values.emailAddress) {
        errors.emailAddress = 'Email Address requires a value';
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = 'Email address appears invalid';
      }

      if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone Number requires a value';
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
      }
      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = 'U.S. Military affiliation requires a value';
      }
      if (values.isAssociate === '' || values.isAssociate === undefined) {
        errors.isAssociate = 'Have you completed your Associates degree? requires a value';
      }

      if (this.state.communityCollegeDataList =='other' && !values.flex_otherCollege) {
        errors.flex_otherCollege = 'College name is required';
      }
      console.log ("step2 errors", errors);
      return errors;
    }

    validate(values) {
      const { currentStep } = this.state;
      const { theme } = this.props;
      const errors = {};
      switch (theme) {
        case 'twostep':
          if (currentStep === 1) Object.assign(errors, this.validateOneStep(values));
          else Object.assign(errors, this.validateTwoStep(values));
          break;
        case 'dynamic':
          if (currentStep === 1) Object.assign(errors, this.validateOneStep(values));
          else Object.assign(errors, this.validateTwoStep(values));
          break;
        default:
          Object.assign(errors, this.validateOneStep(values));
          Object.assign(errors, this.validateTwoStep(values));
          break;
      }
      return errors;
    }

    render() {
      const { classes } = this.props;
      const {
        initialValues,
      } = this.state;

      return (
        <div className="inlineFormContainer">
          <section
            aria-label="Request For Information"
            className={classes.root}
          >
            
              <Grid container className="inlineFormGrid" ref={this.ref.container}>
                <Grid item xs={12}>
                  <div className={classes.inlineFormTitle}>{unescapeHTML(this.getTitleText())}</div>
                </Grid>
                <Grid item md={12} sm={12} lg={12} xs={12} >
                  <Grid>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={this.handleSubmit}
                      validate={values => this.validate(values)}
                      innerRef={form => this.ref.form.current = form}
                    >
                      {() => this.renderForm()}
                    </Formik>
                  </Grid>
                </Grid>
              </Grid>
          </section>
          {/*    <Footer />  */}
        </div>
      );
    }

    renderForm() {
      return (
        <Form
          className="mobile-form fix-notched-outline"
          aria-label="Request For Information"
          style={{ position: 'relative' }}
          onChange={this.handleAnalyticsRFIFormStart}
        >
          {this.renderFormContent()}
          <FormikErrorFocus
            offset={-50}
            align="top"
            focusDelay={200}
            ease="linear"
            duration={1000}
          />
        </Form>
      );
    }

    renderFormContent() {
      const { theme } = this.props;
      switch (theme) {
        default:
          return (
            <Grid container className="MuiOutlinedInput-noOutline">
               {this.renderInputFullName()}
               {this.renderInputEmail()}
               {this.renderInputPhoneNumber()}
               {this.renderInputZipCode()}
               {this.renderSelectFieldOfStudy()}
               {this.renderOptionIsMilitary()}
               {this.renderIsRegisteredNurseOption()}
               {this.renderInputCommunityDropdown()}
               {this.renderInputCommunityCollegeDropdown()}
               {this.renderInputOtherCollegeName()}
              {this.renderOptionIsAssosiate()}
              {/*this.renderInputCurrentSemesterDropdown()}       
              {this.renderCheckboxHasStateConsent() */}
                {this.renderLegalText()}
                {this.renderSubmitButton()}
              
            </Grid>
          );
        case 'twostep':
          if (this.state.currentStep === 1) {
            return (
              <Grid container className="MuiOutlinedInput-noOutline">
                {this.renderStepFields()}
                {this.renderSelectFieldOfStudy()}
                {this.renderIsRegisteredNurseOption()}
                {this.renderSelectDegreeLevel()}
                {this.renderInputEducation()}
                {this.renderInputZipCode()}
                {this.renderOptionHasCommunicationConsent()}
                {this.renderCheckboxIsCBE()}
                <div className="legal-text">
                  {this.renderNextButton()}
                </div>
              </Grid>
            );
          }
          return (
            <Grid container className="MuiOutlinedInput-noOutline">
              {this.renderStepFields()}
              {this.renderInputFullName()}
              {this.renderInputEmail()}
              {this.renderInputPhoneNumber()}
              {this.renderOptionIsMilitary()}
              {/* this.renderCheckboxHasStateConsent() */}
              <div className="legal-text">
                {this.renderLegalText()}
                {this.renderSubmitButton()}
              </div>
            </Grid>
          );
        case 'dynamic':
          if (this.state.currentStep === 1) {
            return (
              <Grid container className="MuiOutlinedInput-noOutline">
                {this.renderStepFields()}
                {this.renderSelectFieldOfStudy()}
                {this.renderIsRegisteredNurseOption()}
                {this.renderSelectDegreeLevel()}
                {this.renderInputZipCode()}
                {this.renderInputEducation()}
                {this.renderOptionHasCommunicationConsent()}
                {this.renderCheckboxIsCBE()}
                <div className="legal-text">
                  {this.renderNextButton()}
                </div>
              </Grid>
            );
          }
          return (
            <Grid container className="MuiOutlinedInput-noOutline">
              {this.renderStepFields()}
              {this.renderInputFullName()}
              {this.renderInputEmail()}
              {this.renderInputPhoneNumber()}
              {this.renderOptionIsMilitary()}
              {/* this.renderCheckboxHasStateConsent()*/ }
              <div className="legal-text">
                {this.renderLegalText()}
                {this.renderSubmitButton()}
              </div>
            </Grid>
          );
      }
    }

    renderStepFields() {
      const { classes } = this.props;
      const { currentStep } = this.state;
      return (
        <Grid item xs={12} className="margin-OneStepForm">
          <div className={classes.stepSelectionContainer}>
            <div
              className={classes.stepSelection}
              tabIndex={0}
              onClick={() => this.changeStep(1)}
            >
              <span
                className={classes.navCircle + (currentStep === 1 ? ' active' : '')}
              >
                1
              </span>
              <span className={classes.stepSelectionText}>Area of Interest</span>
            </div>
            <div
              className={classes.stepSelection}
              tabIndex={(currentStep === 2 ? '0' : '-1')}
              onClick={() => this.changeStep(2)}
            >
              <span
                className={classes.navCircle + (currentStep === 2 ? ' active' : '')}
              >
                2
              </span>
              <span className={classes.stepSelectionText}>Contact Info</span>
            </div>
          </div>
        </Grid>
      );
    }

    renderSelectFieldOfStudy() {
      const classes = ['margin-OneStepForm'];
      if (this.isFieldHidden('fieldOfStudy')) classes.push('hidden');
      return (
        <Grid item md={12} sm={12} lg={6} xs={12} className={classes.join(' ')}>
          <SelectField
            id="fieldOfStudy"
            name="fieldOfStudy"
            label="Field of study *"
            data={this.state.fieldOfStudyOptions}
            className="MuiFormControl-fullWidth"
            formRef={this.ref.form}
            onClick={this.handleAnalyticsRFIFormStart}
            onChange={e => this.dropdownChange('fieldOfStudy', e.target.value)}
          />
        </Grid>
      );
    }

    renderInputCommunityDropdown() {
      const classes = ['margin-OneStepForm'];      
      return (
        <Grid item md={12} sm={12} lg={6} xs={12} className={classes.join(' ')}>
          <SelectField
            id="flex_CommunityDropdown"
            name="flex_CommunityDropdown"
            label="Name of college *"
            data={communityCollegeList}
            className="MuiFormControl-fullWidth"
            formRef={this.ref.form}
            onClick={this.handleAnalyticsRFIFormStart}
            onChange={e => this.dropdownChange('flex_CommunityDropdown', this.setState({ communityCollegeDataList:e.target.value}))}
          />
        </Grid>
      );
    }
    renderInputCommunityCollegeDropdown() {
      const classes = ['margin-OneStepForm'];      
      return ( this.state.communityCollegeDataList != undefined && this.state.flex_CommunityCollegeDropdown[this.state.communityCollegeDataList] != undefined) ? (
        <Grid item md={12} sm={12} lg={6} xs={12} className={classes.join(' ')}>
          <SelectField
            id="flex_CommunityCollegeDropdown"
            name="flex_CommunityCollegeDropdown"
            label="Select community college*"
            data={this.state.flex_CommunityCollegeDropdown[this.state.communityCollegeDataList]}
            className="MuiFormControl-fullWidth"
            formRef={this.ref.form}
            onClick={this.handleAnalyticsRFIFormStart}
            onChange={e => this.dropdownChange('flex_CommunityCollegeDropdown',e.target.value )}
          />
        </Grid>
      ): null;
    }
     

    renderInputCurrentSemesterDropdown() {
      const classes = ['margin-OneStepForm'];     
      return !this.state.isAssociateSelected && (
        <Grid item md={12} sm={12} lg={6} xs={12} className={classes.join(' ')}>
          <SelectField
            id="flex_CurrentSemester"
            name="flex_CurrentSemester"
            label="CurrentSemester"
            data={semesterDropdown}
            className="MuiFormControl-fullWidth"
            formRef={this.ref.form}
            onClick={this.handleAnalyticsRFIFormStart}
            onChange={e => this.dropdownChange('flex_CurrentSemester', e.target.value)}
          />
        </Grid>
      )//: null;
    }

    renderIsRegisteredNurseOption() {
      const classes = ['selectRadioLabel MuiFormControl-fGBlack margin-OneStepForm '];
      if (this.isFieldHidden('isRegisteredNurse')) classes.push('hidden');
      return this.isRegisteredNurseOptionVisible() ? (
        <Grid item md={12} sm={12} lg={6} xs={12} className={classes.join(' ')}>
          <MaterialRadio
            className="selectRadioLabel"
            value={this.ref.form?.current?.values.isRegisteredNurse}
            row
            onChange={this.handleChange}
            items={radioOption}
            label="Are you currently a Registered Nurse? *"
            name="isRegisteredNurse"
            ariaLabel="Are you currently a Registered Nurse?"
          />
        </Grid>
      ) : null;
    }

    renderSelectDegreeLevel() {
      const classes = ['margin-OneStepForm'];
      if (this.isFieldHidden('degreeLevel')) classes.push('hidden');
      return (
        <Grid item xs={12} className={classes.join(' ')}>
          <SelectField
            id="degreeLevel"
            name="degreeLevel"
            label="Degree level"
            data={this.state.degreeLevel}
            className="MuiFormControl-fullWidth"
            aria-required="true"
            formRef={this.ref.form}
            onClick={this.handleAnalyticsRFIFormStart}
            onChange={e => this.dropdownChange('degreeLevel', e.target.value)}
          />
        </Grid>
      );
    }

    renderInputFullName() {
      return (
        <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
          <MaterialInput
            name="fullName"
            id="fullName"
            type="text"
            label="First and last name *"
            ariaRequired="true"
            ariaLabel="Enter your full name"
          />
        </Grid>
      );
    }

    renderInputEmail() {
      return (
        <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
          <MaterialInput
            name="emailAddress"
            id="emailAddress"
            type="email"
            label="Email address *"
            ariaRequired="true"
            ariaLabel="Enter Email address"
            variant="outlined"
          />
        </Grid>
      );
    }

    renderInputPhoneNumber() {
      return (
        <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
          <MaterialPhoneNumber
            name="phoneNumber"
            id="phoneNumber"
            label="Phone number *"
            ariaRequired="true"
            ariaLabel="Enter phone number"
            handleBlur={this.handlePhoneNumberBlur}
          />
        </Grid>
      );
    }
    
      renderInputZipCode() {
      return (
        <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
          <ZipCode
            value={this.ref.form?.current?.values.postalCode}
            type="zipcode"
            id="zipcode"
            label="Zip Code *"
            aria-label="Enter Zip code"
            ariaRequired="true"
            handleChange={this.handleZipCodeChange}
            formRef={this.ref.form}
          />
        </Grid>
      );
    }
    renderInputEducation() {
      if (!this.isFieldEnabled('education')) return null;
      return (
        <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
          <MaterialInput
            name="flex_Community_College_University__c"
            id="flex_Community_College_University__c"
            type="text"
            label="College or university"
            aria-required="false"
            aria-label="Enter a college or university name"
          />
        </Grid>
      );
    }

    renderInputOtherCollegeName() {
      return ( this.state.communityCollegeDataList =='other' && (
        <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
          <MaterialInput
            name="flex_otherCollege"
            id="flex_otherCollege"
            type="text"
            label="Other*"
            aria-required="true"
            ariaLabel="other college name"
          />
        </Grid>
      )//:null;
      );
    }
      renderOptionHasCommunicationConsent() {
      if (!this.isFieldEnabled('isScholarship')) return null;
      return (
        <Grid item xs={12} className="selectRadioLabel MuiFormControl-fGWhite margin-OneStepForm">
          <MaterialRadio
            className="selectRadioLabel"
            value={this.ref.form?.current?.values.isScholarship}
            row
            onChange={this.handleChange}
            items={radioOption}
            label="Would you like to know about scholarships?"
            name="isScholarship"
            ariaLabel="Would you like to know about scholarships?"
          />
        </Grid>
      );
    }

    renderOptionIsAssosiate() {
      return (
        <Grid item md={12} sm={12} lg={6} xs={12} className="selectRadioLabel MuiFormControl-fGBlack margin-OneStepForm">
          <MaterialRadio
            className="selectRadioLabel"
            value={this.ref.form?.current?.values.isAssociate}
            row
            onChange={this.handleChange}
            items={radioOption}
            label="Have you completed your Associate's degree?*"
            name="isAssociate"
            ariaLabel="Have you completed your Associate's degree?"
          />
        </Grid>
      );
    }
    
    renderOptionIsMilitary() {
      return (
        <Grid item md={12} sm={12} lg={6}  xs={12} className="selectRadioLabel margin-OneStepForm">
          <MaterialRadio
            className="selectRadioLabel"
            value={this.ref.form?.current?.values.isMilitary}
            row
            onChange={this.handleChange}
            items={radioOption}
            label="Do you have any U.S. Military affiliation? *"
            name="isMilitary"
            ariaLabel="Do you have any U.S. Military affiliation?"
          />
        </Grid>
      );
    }

    /* renderCheckboxHasStateConsent() {
      return (this.state.stateProvince === 'IN'
        ? (
          <Grid item xs={12} className="MuiFormControl-fGWhite margin-OneStepForm">
            <FormikCheckbox
              type="checkbox"
              id="hasConsent"
              name="hasConsent"
              className="disclaimer-checkbox"
              onChangeCallback={this.handleStateConsent}
              label="I am an Indiana resident. By clicking this box, I expressly give University of Phoenix authority and permission to call me regarding its educational programs and services at the telephone number I have provided."
            />
          </Grid>
        ) : null
      );
    } */
    renderCheckboxIsCBE() {
      return (conditionalCBE(this.ref.form?.current?.values.fieldOfStudy, this.ref.form?.current?.values.degreeLevel)
              || this.isFieldEnabled('isCBE')
        ? (
          <Grid item xs={12} className="MuiFormControl-fGWhite margin-OneStepForm">
            <div className='cbe'>
              <SingleCheckbox
                className="cbe-checkbox"
                id="isCBE"
                label={unescapeHTML(this.getCBEText())}
                /* ariaLabel={unescapeHTML(this.getCBEText())} */
                checkboxName="isCBE"
                parentCallback={this.isCBEConsent}
                // defaultChecked={initialValues.isCBE}
                defaultValue={this.ref.form.current.values.isCBE}  
              />
            </div>
          </Grid>
        ) : null
      );
    }




    renderLegalText() {
      return (
        <div className="disclaimer">
          {unescapeHTML(disclaimerText)}
        </div>
      );
    }

    renderNextButton() {
      return (
        <Button
          size="small"
          type="button"
          className="btn-submit inline-rfi-btn"
          onClick={e => this.handleNextButton(e)}
        >
          Next step
        </Button>
      );
    }

    renderSubmitButton() {
      return (
        <Grid item md={12} sm={12} lg={3} xs={12} >
        <Button size="large" type="submit" className="btn-submit ">
          Request Information
        </Button>
         Ready?&nbsp;
         <a class="cmp-button-applynow"style={{color:'#db3725',textDecoration:'none',fontSize:'16px'}} role="link" data-location="content"  href="https://www.phoenix.edu/application/quick-app/personal-info?html?channel=CCLP">
         <span class="cmp-button__text_applynow" >
          Apply now
         </span>
         </a>
       </Grid>
      );
    }
}

CommunityCollegeTransferForm.defaultProps = {
  classes: {},
  title: null,
  theme: 'default',
  fieldsEnabled: '',
  fieldsHidden: '',
  fieldOfStudyOptions: null,
  degreeLevelOptions: null,
  // fieldOfStudy: 'healthcare'
};

CommunityCollegeTransferForm.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.instanceOf(Object),
  fieldsEnabled: PropTypes.string,
  fieldsHidden: PropTypes.string,
  fieldOfStudyOptions: PropTypes.string,
  degreeLevelOptions: PropTypes.string,
};

//const CommunityCollegeTransferFormStyled = withStyles(styles)(CommunityCollegeTransferForm);

const generateClassName = createGenerateClassName({
  productionPrefix: 'inlineRFI',
});

export default function (props) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <CommunityCollegeTransferForm {...props} />
    </StylesProvider>
  );
}
