import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
//import { withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import date from 'date-and-time';
//import styles from '../layout/styles/MobileFormStyles';
//import initialEmailFormValues from './EmailusFormInitialValues';
//import styles from '../inline/SubscribeStyles';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { submitForm, mapAdditionalFields, feedDataToSessionStorage, loadLocalStorageData, unescapeHTML, userData, fireEvent, fireAnalyticsEvent, fireNonRFISubmitEvent, getmcid, profanityCheck } from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocusDialog';
import Button from '../layout/MaterialComponents/button/Button';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
//import checkmark from '../layout/images/checkmark.svg';
import { initValues, processJSONParameter } from '../common/configTools';


const formRef = {};
let defaultTextHTML = '<h2 id="dialogTitle" class="newOneForm-title" style="display:flex; align-items:center; justify-content: center; margin-top:0;">Explore your savings</h2>';
let defaultDescriptionTextHTML = '<div id="dialogDesc" class="newOneForm-SubTitle" style="font-weight: 400">Get the <strong style="color:#307746;">Scholarships and Savings Guide</strong> to help you learn all the ways you can save on your education.</div>';
 const initialEmailFormValues = {
    fullName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    flex_more_info__c: '',
    flex_Info_Requested__c: 'something_else',
    appContext: 'EMAILCAPTURE',
    mcid: '',
    useCaseContext: 'NEW',
    flex_EmailCaptureSource: 'SCHL',
    userAgent: '',
    campaign: 'PHXEDU10',
    source: 'INET',
    requestingIP: '',
    requestingUrl: '',
    disclaimer: 'This information is available on <a tabindex="0" href="https://www.phoenix.edu/">phoenix.edu</a> but has been organized into one central source for you to reference quickly and easily. By submitting this form, you consent to receiving University of Phoenix emails about our educational programs and services. Visit our <a tabindex="0" href="https://www.phoenix.edu/copyright-legal/privacy_policy.html">Privacy Policy</a> for more information.',
  };
const disclaimerText = initialEmailFormValues.disclaimer;
const ecidValue = getmcid();
let preProspectInfoData = {};
let device;
let tcValue = '';
//const isTabletView = window.innerWidth <= 990;
const isMobileView = window.innerWidth <= 640;
let formStep;
let formType;
let formName;

function srSpeak(text, priority) {
    try{
        var el = document.createElement("div");
        var id = "make-a-screen-reader-talk-" + Date.now();
        el.setAttribute("id", id);
        el.setAttribute("aria-live", priority || "polite");            
        el.classList.add("visually-hidden");
        document.body.appendChild(el);
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = text;      
        }, 100);
        
        window.setTimeout(function () {
            if (document.getElementById(id)){
                document.body.removeChild(document.getElementById(id));
            }
        }, 1000);
    } catch(e){
        console.log ("error", e);
        throw e;
    }
}

function trapFocus(element, focusElmtValue) {
    //console.log ("element", element);
    var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
    //console.log ("focusableEls", focusableEls);
    var firstFocusableEl = focusableEls[focusElmtValue];  
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    //console.log ("focusableEls.length, firstFocusableEl, lastFocusableEl", focusableEls.length, firstFocusableEl, lastFocusableEl);
    element.addEventListener('keydown', function(e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
      if (!isTabPressed) { 
        return; 
      }
      if ( e.shiftKey ) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          //console.log ("lastFocusableEl", lastFocusableEl);
          lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          //console.log ("firstFocusableEl", firstFocusableEl);
          firstFocusableEl.focus();
            e.preventDefault();
          }
        }
    });
  }

class PreprospectModal extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialEmailFormValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
    }

    componentDidMount() {
        const { props } = this;
        //console.log ("props ", props);
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
        initialValues.flex_EmailCaptureSource = 'SCHL';
        initialValues.thankyouPageUrl= '';
        initValues(initialValues, this.props); 
        loadLocalStorageData(initialValues);      
        this.setState({ initialValues }); 
        //this.setState({ isSubmit: true }); 
        getIPAddress(this.updateIPAddress);
        if (isMobileView){device = 'Mobile';}else {device = 'Desktop';}
        const { currentStep } = this.state;
        formStep = currentStep;
        formType = 'Preprospect Zero Party';
    	formName = this.props.formname || 'Pre Prospect Modal ';
        formName = formName + device;
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
        // KEYDOWN EVENT
        if (document.querySelector('.abandonment input[name="fullName"]')) {
            document.querySelector('.abandonment input[name="fullName"]').addEventListener("keydown", function(e) {
                if(e.keyCode == 9 || e.key === 'Tab') {
                    setTimeout(function () {
                        if (document.querySelector('[id^="fullName"][id$="-helper-text"]')) {
                            document.querySelector('input[name="emailAddress"]').focus()
                        }
                    }, 300);
                }
            });
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.requestingIP = ipAddress;
        this.setState({ initialValues });
        
    }

    setAnalytics = (formname) => {
           
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireEvent('PreprospectModalStart', 'Zero Party', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }    
      
    isTransferCredit = (event, checked) => {
        //console.log(event); 
        //console.log(checked)
        formRef.current.values.flex_Transfer_Credits_from_ValueExchange__c = checked;
        if (checked === true) {
            tcValue='checked';
        }
        if (checked === false) {
            tcValue='unchecked';
        }         
    }

    handleSubmit = async values => {
      if (document.activeElement.name === "submit") {
        //console.log ("in handle submit")
        if (((values.fullName).trim().indexOf(' ')) > 0) {
            const i = (values.fullName.trim()).indexOf(' ');
            values.firstName = (values.fullName.trim()).substring(0, i); 
            values.lastName = (values.fullName.trim()).substring(i + 1);
        }    
        this.setState({ initialValues: values });  
        window.uopSegment&&window.uopSegment.identify({'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress});
        window.uopSegment&&window.uopSegment.track('Preprospect Info Submitted', {'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress, 'analytics': {'mcid': ecidValue}});
        //window.uopxDataLayer&&window.uopxDataLayer.track('PreProspectInfoSubmitted', {'componentName' : 'RequestInfo', 'name': formName, 'type': formType, 'formInformation': {'profileDetails':{'firstName':values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}}, 'external': {'segmentAnonymousId': window.analytics.user().anonymousId(), 'segmentUserId': window.analytics.user().id()}});
        srSpeak('email submitted');
        //submitForm(values, this.props);
        //fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //this.setState({ isSubmit: true, currentStep:2}); 
        mapAdditionalFields(values);
        feedDataToSessionStorage(values);
        values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        Object.keys(values).map(k => values[k] = typeof values[k] === 'string' ? values[k].trim() : values[k]);
        Object.keys(values).forEach(k => (values[k] === '' || values[k] === undefined || values[k] === null) && delete values[k]);

        let postURL = process.env.REACT_APP_PHXEDU_PROD_POST_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            postURL = process.env.REACT_APP_PHXEDU_STAGE_POST_URL;
        }
        
        try {
            const response = await fetch(postURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
            });
        // console.log ("form submit values" , values);

        this.setState({
            initialValues: values,
            isSubmit: true,
            currentStep:2,
            message: null,
            // message: <div style={styles.success}>{responseJSON.message || 'No Message Given'}</div>,
        });
        //setTimeout(function(){trapFocus (document.querySelector('#modalrfiPPSG'), 0);}, 1000);
        //  setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
        //    fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType);
            if (window.uopxDataLayer && window.uopxDataLayer.track) {
                window.uopxDataLayer&&window.uopxDataLayer.track('PreprospectModalSubmit', {'componentName' : 'Zero Party', 'name': formName, 'type': formType, 'text': 'Request my guide', 'formInformation': {'profileDetails':{'firstName' :values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}, 'formDetails':{'campaign' : 'Scholarship Transfer Credits', 'campaignOption' : tcValue},},});
            }
        } catch (e) {
        this.setState({
            message: <div className="">{e.message || e}</div>,
        });
        }
        if (window.FS) {
            preProspectInfoData.source= "ScholarshipGuideExitForm";
            //calling FS and passing the tracking data set
            window.FS.event('Submitted PreProspectInfo', preProspectInfoData);
            //console.log("Submitted PreProspectInfo", preProspectInfoData);
        }
    //    document.querySelector('#announce').innerHTML = 'email submitted';
        if (document.querySelector('.btn-close')) {
            document.querySelector('#downloadcta').focus();
            trapFocus(document.querySelector('#modalrfiPPSG'), 0);
        }
      }   
    }
    
    preprospectModalValidateForm(values) {
        const errors = {};
        if (!values.fullName) {
            errors.fullName = 'First and Last name requires a value';
          } else if (values.fullName.trim().indexOf(' ') === -1) {
            errors.fullName = 'Please enter a First and Last name';
          } else if (!profanityCheck(values.fullName)) {
            errors.fullName = 'Full Name appears invalid';
          }
        if (!values.emailAddress) {
          errors.emailAddress = 'Email Address requires a value';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          errors.emailAddress = 'Email address appears invalid';
        }
      //  console.log('errors', errors, values);
        return errors;
    }

    /*modalClosebtn = () => {
        this.setState({ isSubmit: false, currentStep:1});
    } */

    keydownDownload = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            this.handleDownload;
        }         
    }

    handleDownload =(e)=> {
        //window.open('https://www.phoenix.edu/content/dam/edu/tuition-financial-aid/doc/scholarship-guide.pdf', "_blank", "noreferrer");
        const pdfUrl = 'https://www.phoenix.edu/content/dam/edu/tuition-financial-aid/doc/scholarship-guide.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.role = 'link'
        link.target='_blank';
        link.download = "scholarship-guide.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.uopxDataLayer.track("PreprospectModalDownload",{
            'componentName' : 'Zero Party',
            'name': formName, 
            'type': formType,
            'text':'Download Now'
        });
    }

    keydownClose = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            this.modalClose();
        }         
    }

    modalClose = () => {
        console.log ("in modalClose");
    //    const { initialValues } = this.state;
        let modal = document.querySelector("#modalrfiPPSG");
        let overlay = document.querySelector("#overlay-div");
        if (modal !== null || overlay !== null) {
            modal.classList.contains("hidden")?console.log("#modalrfiPPSG already contains 'hidden'"):modal.classList.add("hidden");
            document.querySelector('#overlay-div.hidden')?console.log(".overlay already contains 'hidden'"):overlay.classList.add("hidden");
        }
        /*if ((modal !==null && !modal.classList.contains("hidden")) || (overlay !== null && !overlay.classList.contains("hidden"))) {
            modal.classList.contains("hidden")?console.log("#modalrfiPPSG already contains 'hidden'"):modal.classList.add("hidden");
            overlay.classList.contains("hidden")?console.log(".overlay already contains 'hidden'"):overlay.classList.add("hidden");
        }  else if (modal !== null || overlay !== null){
            modal.classList.contains("hidden")?console.log("#modalrfiPPSG already contains 'hidden'"):modal.classList.add("hidden");
            overlay.classList.contains("hidden")?console.log(".overlay already contains 'hidden'"):overlay.classList.add("hidden");
            modal.classList.add("hidden");
            overlay.classList.add("hidden");
        } */
	    
        window.uopxDataLayer.track("PreprospectModalClose",{
            'componentName' : 'Zero Party',
            'name': formName + " " + this.state.currentStep, // depends on step closing 
            "type": formType,
            "text": "close",
        }); 
        this.setState({ isSubmit: false, currentStep:1});
    };
      
    render() {
    //    const { classes } = this.props;
        const { initialValues,  isSubmit} = this.state;

        let titleText = this.props.title || defaultTextHTML;
        let descriptionText = this.props.description || defaultDescriptionTextHTML;
        let cta = this.props.cta || 'Request my guide';
        //if (isTabletView){cta = 'Request my guide';}
        let thankyouMessageTitle = this.props.thankyoumessagetitle || '<h2 id="thanksdialogtitle" class="mainHeader" style="display:flex; align-items:center; justify-content: center; text-align:center; margin-top:0;" >Thanks for requesting the Scholarships and Savings Guide</h2>';
        let thankyouMessageDescription = this.props.thankyoumessagedescription || '<div id="thanksdialogdesc" class="newOneForm-SubTitle thanks" style="display:flex; align-items:center; justify-content: center; margin-top:0;">Download your pdf guide now. Or access the link in our email.</div>';

        //console.log ("titleText, descriptionText, cta, thankyouMessageTitle, thankyouMessageDescription", titleText, descriptionText, cta, thankyouMessageTitle, thankyouMessageDescription);
        return (    
            <div>
            {/* <UOPHeader /> */}
                <section className="abandonment">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">
					    {/*	<Grid item md={12} sm={12} lg={12} xs={12} >
						<div>{unescapeHTML(titleText)}</div>
                        <div className="newOneForm-SubTitle" style={{ fontSize: '18px', fontWeight: 700 }}>Email us &mdash; and we’ll get the ball rolling.</div>
                        <div className="reqinfo" > * Required information </div>
						</Grid>   */}
                            <Grid item md={12} sm={12} lg={12} xs={12} style={{ maxWidth:'900px' }}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.preprospectModalValidateForm(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    <Form className="mobile-form"
                                        aria-labelledby={isSubmit? "thanksdialogtitle thanksdialogdesc" : "dialogTitle dialogDesc"}
                                        style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                        <Grid container >
                                            
                                    {!isSubmit
                                        ? (
                                            <>  
                                                <Grid item md={6} sm={6} lg={6} xs={12} className="margin-OneStepForm modalimg rfiabandonment" role="img" aria-label="Cover of the Scholarships and Savings Guide with an image of smiling graduate dressed in a cap and gown.">
                                                    <div className=""> </div>
                                                </Grid>
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm rfiabandonmentcopy">
                                                    {unescapeHTML(titleText)}
                                                    {unescapeHTML(descriptionText)}
                                                    <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                                                    <MaterialInput
                                                        name="fullName"
                                                        id="fullName"
                                                        label="First and Last name *"
                                                        ariaRequired="true"
                                                        ariaLabel="Enter your full name"
                                                        variant="outlined"
                                                        className='uopx-input footeremail modalfield'
                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                    />
                                                    <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" className='uopx-input footeremail modalfield' />
                                                    <SingleCheckbox
                                                        id="flex_Transfer_Credits_from_ValueExchange__c"
                                                        className='ways2save-checkbox'
                                                        label="I have prior college credits."
                                                        ariaLabel="I have prior college credits."
                                                        checkboxName="flex_Transfer_Credits_from_ValueExchange__c"
                                                        parentCallback={this.isTransferCredit}
                                                    />
                                                    <div className="disclaimer"> {unescapeHTML(disclaimerText)} </div>
                                                    <Button name='submit' size='large' color='secondary' type='submit' className='rfi-btn-submit submit-btn'> {unescapeHTML(cta)} </Button>
                                                    {isMobileView && <Button size='small' variant = 'Secondary' color='secondary' onClick={this.modalClose} onMouseDown={this.modalClose} onKeyDown={this.keydownClose} className='nothanks' style={{color:'#5e7079', margin:'auto', marginTop:'16px'}}> No thanks </Button> }
                                                </Grid> 
												<ErrorFocus />
                                            </>
                                        ):(
                                            <>
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm modalimg-thanks rfiabandonment" role="img" aria-label="Image of a piggy bank and a stack of gold coins.">
                                                    <div className=""> </div>
                                                </Grid>
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm modalthankscopy" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                <div className ="rfiabandonmentcopy">
                                                    {unescapeHTML(thankyouMessageTitle)}
                                                    {unescapeHTML(thankyouMessageDescription)}
                                                    <div style={{ padding: '1.5rem 3rem'}}>
                                                        <div>
                                                            <Button size='large' id='downloadcta' color='secondary' onClick={this.handleDownload} onKeyDown={this.keydownDownload} className='rfi-btn-submit btnclose' ariaLabel='Download now, opens in new window' aria-labelledby='thanksdialogtitle thanksdialogdesc downloadcta'>Download now</Button>
                                                        </div>              
                                                        <div>
                                                            <Button size='small' variant='Secondary' color='secondary' onClick={this.modalClose} onMouseDown={this.modalClose} onKeyDown={this.keydownClose} className='' style={{color:'#5e7079', margin:'auto', fontSize:'18px', marginTop:'16px'}}> Close </Button>
                                                        </div>
                                                    </div>
                                                </div>    
                                                </Grid>
                                                {/* this.modalClose() */}
                                            </> 
                                          )}
                                          <button id="close-modal" aria-label="Close dialog" title="Close" onClick={this.modalClose} onKeyDown={this.keydownClose} type="button" class="btn-close btn-sg-mb"></button>
                                          </Grid>
                                        </Form> 
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
        {/*    <Footer /> */}
            </div>
        );
    }
}

PreprospectModal.defaultProps = {
    classes: {},
};

PreprospectModal.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default PreprospectModal;
//export default withStyles(styles)(PreprospectModal);